var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('loading',{attrs:{"active":_vm.loading},on:{"update:active":function($event){_vm.loading=$event}}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticStyle:{"font-weight":"800","color":"#577eba"}},[_vm._v("สร้างข่าวสารและกิจกรรม")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("เพิ่มข่าวสารและกิจกรรม")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersBrand,"items":_vm.items,"search":_vm.search,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.keyImg",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"ma-1",attrs:{"src":item.keyImg,"width":"100px"}})]}},{key:"item.news_title",fn:function(ref){
var item = ref.item;
return [(item.news_title.length < 50)?_c('span',[_vm._v(_vm._s(item.news_title))]):_c('span',[_vm._v(_vm._s(item.news_title.substr(0, 50))+"...")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description.length < 50)?_c('span',[_vm._v(_vm._s(item.description))]):_c('span',[_vm._v(_vm._s(item.description.substr(0, 50))+"...")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.UpdateBanner(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.DeleteBanner(item)}}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }