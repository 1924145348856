<template>
  <div>
    <ManageBanners_4 />
  </div>
</template>
<script>
import ManageBanners_4 from "@/components/Banners_4/ManageBanners_4";
export default {
  components: {
    ManageBanners_4,
  },
  created() {
  },
};
</script>
